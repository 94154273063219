import Link, { LinkProps } from 'next/link'
import { ReactNode } from 'react'

import { ButtonProps } from '../Button'

export interface ButtonLinkProps extends LinkProps {
  className?: string
  children?: ReactNode
  color?: ButtonProps['color']
  disabled?: boolean
  href: string
  variant?: 'contained' | 'outlined' | 'inverted' | 'text'
  size?: 'base' | 'large'
  isExternal?: boolean
  download?: boolean
}

export const ButtonLink = ({
  className = '',
  children,
  color = 'primary',
  disabled = false,
  variant = 'contained',
  size = 'base',
  isExternal = false,
  download = false,
  ...props
}: ButtonLinkProps) => {
  const buildButtonVariantClasses = () => {
    if (variant === 'contained') {
      return 'button-contained'
    }
    if (variant === 'outlined') {
      return 'button-outlined'
    }
    if (variant === 'inverted') {
      return 'button-inverted'
    }
    if (variant === 'text') {
      return 'button-text'
    }
  }

  const buildButtonColorClasses = () => {
    return color ? `button-${color}` : ''
  }

  const buildDisabledClasses = () => {
    return disabled ? 'button-disabled' : ''
  }

  const buildButtonSizeClasses = () => {
    return size === 'large' ? 'button-large' : ''
  }

  return (
    <Link
      download={download}
      {...props}
      target={isExternal ? '_blank' : '_self'}
      className={`button ${buildButtonVariantClasses()} ${buildButtonColorClasses()} ${buildDisabledClasses()} ${buildButtonSizeClasses()} ${className}`}
    >
      {children}
    </Link>
  )
}
